@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  /* max-width: 100%; */
  /* overflow-x: hidden; */
  /* overflow-y: hidden; */
}

.App {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

body {
  background-color: #003d26 !important;
  padding: 0;
  padding-bottom: 0.1rem;
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
}

.container {
  margin: 0 auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  color: #F7F2EA !important;
  transition: color .5s;
}

a:hover {
  color: #1d9c76 !important;
  transition: color .5s;
}

p {
  font-size: 1.25rem;
  margin: 0 !important;
}

p,
h1,
h2,
h3,
h4 {
  color: #F7F2EA;
}

h1,
h2,
h3,
h4 {
  font-weight: 600;
  margin: 0;
  text-align: center;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

hr {
  border: none !important;
  border-top: 2px solid #F7F2EA !important;
  width: 80% !important;
  margin: 0.5rem auto !important;
  opacity: 1 !important;
}

.carousel-inner {
  height: 100% !important;
  width: 100% !important;
}

.carousel-item {
  /* display: relat !important; */
  height: 100% !important;
  width: 100% !important;
}

.link {
  color: #41ffc6
}

button {
  padding: 0.6rem;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid white;
  font-size: 1rem !important;
  font-weight: 500;
  color: white;
  transition: all 0.5s;
}

button:hover {
  background-color: #F7F2EA;
  color: #003d26;
  transition: all 0.5s;
}

.card {
  width: 100%;
  background-color: white;
  border-radius: 0.5rem;
}

:not(p) {}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .container {
    width: 960px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .container {
    width: 720px;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .container {
    width: 540px;
  }

  button {
    font-size: 1.3rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.5rem;
  }
}

@media (max-width: 575px) and (min-width: 0px) {
  .carousel-indicators {
    margin-bottom: 0 !important;
  }

  .container {
    width: 90%;
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.3rem;
  }

  button {
    font-size: 0.8
      /* margin: auto 0; */
      rem;
  }

  p {
    font-size: 1rem;
  }
}

table {
  border-collapse: collapse;
  line-height: 1.1;
}

th {
  padding: 10px;
  border: 1px solid #003d26 !important;
  text-align: center;
}

td {
  font-size: 0.8em;
  padding: 5px 7px;
  border: 1px solid #003d26 !important;
  text-align: center;
}

*::-webkit-scrollbar {
  width: 14px;              
}
*::-webkit-scrollbar-track {
  background: #003d26; 
  transition: all overflow1s;       
}
*::-webkit-scrollbar-track:hover {
  background: #002c1b;    
  transition: all 1s;       
}
*::-webkit-scrollbar-thumb {
  background-color: #F7F2EA;   
  border-radius: 3px !important; 
}